import { render, staticRenderFns } from "./PartnersCarousel.vue?vue&type=template&id=2331ff56&"
import script from "./PartnersCarousel.vue?vue&type=script&lang=js&"
export * from "./PartnersCarousel.vue?vue&type=script&lang=js&"
import style0 from "./PartnersCarousel.vue?vue&type=style&index=0&id=2331ff56&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports